import React from "react";
import styled from "styled-components";

const SpreadHappiness = () => {
    const DATA = [
        {
            image: "img/photo gallery/Where Happeiness Spread/E1.jpg",
            title: "Cow",
            description: "description",
        },
        {
            image: "img/photo gallery/Where Happeiness Spread/E2.jpg",
            title: "Cow",
            description: "",
        },
        {
            image: "img/photo gallery/Where Happeiness Spread/E3.jpg",
            title: "Cow",
            description: "",
        },
        {
            image: "img/photo gallery/Where Happeiness Spread/E7.jpg",
            title: "Cow",
            description: "",
        },
        {
            image: "img/photo gallery/Where Happeiness Spread/E5.jpg",
            title: "Cow",
            description: "",
        },
    ];

    return (
        <div className="breeder-details-page mt-20">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="page-title text-center">Spread Happiness</h2>
                    </div>
                </div>
       
                    {DATA.map(({ image, description, title }) => {
                        return (
                            <RowContainer className="row py-5">
                                <div className="image-box col-md-5">
                                    <Image src={image} alt="Food Provision" />
                                </div>
                                <div className="content-box col-md-7">
                                    <div>
                                        <h3> {title} </h3>
                                        <p> {description} </p>
                                    </div>
                                </div>
                            </RowContainer>
                        );
                    })}
             
            </div>
        </div>
    );
};



const RowContainer = styled.div`
    margin: 20px 0;

    @media screen and (min-width: 768px) {
        &:nth-child(even) .content-box {
            order: -1;
        }
    }

    .content-box {
        margin-top: 10px;
    }
`;

const Image = styled.img`
    max-width: 100%;

    height: auto;
    border-radius: 10px;
`;

export default SpreadHappiness;

