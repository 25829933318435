import React from "react";
import Slider from "react-slick";
function Brand() {
    const TOTAL_IMAGES = 5;

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        arrows: false,
        slidesToShow: 6,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };
    return (
        <div className="brand-area pt-80 pb-80">
            <div className="container">
                <Slider className="row brand-active" {...settings}>
                    {Array(TOTAL_IMAGES)
                        .fill("")
                        .map((_, index) => (
                            <div className="col-12" key={index}>
                                <div className="brand-item">
                                    <img width={100} src={"/img/brand/" + (index + 1) + ".png"} alt="cow" />
                                </div>
                            </div>
                        ))}
                    {Array(TOTAL_IMAGES)
                        .fill("")
                        .map((_, index) => (
                            <div className="col-12" key={index}>
                                <div className="brand-item">
                                    <img width={100} src={"/img/brand/" + (index + 1) + ".png"} alt="cow" />
                                </div>
                            </div>
                        ))}
                </Slider>
            </div>
        </div>
    );
}

export default Brand;
