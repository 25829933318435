import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import Logo from "./Logo";
import $ from "jquery";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

function Header() {
    const header = useRef();

    useGSAP(
        () => {
            const timeline = gsap.timeline();
            timeline
                .from(".navigation li:not(li li)", {
                    opacity: 0,
                    y: 20,
                    stagger: 0.2,
                })
                .from(".header-action li", {
                    opacity: 0,
                    x: 20,
                    stagger:0.1
                });
        },
        { scope: header }
    );

    const MENU_ITEMS = [
        {
            name: "Home",
            link: "/",
        },
        {
            name: "BPSG Essentials",

            subMenu: [
                { name: "About BPSG", link: "/about" },
                { name: "Chairman's Message", link: "/message-from-chairman" },
                { name: "President's Message", link: "/message-from-president" },
                { name: "Spread Happiness", link: "/spread-happiness" },
                { name: "Production", link: "essentials/production" },
                { name: "Events & Updates", link: "/events-updates" },
            ],
        },
        {
            name: "BPSG Operations",

            subMenu: [
                { name: "Our Facilities", link: "/our-facilities" },
                { name: "Food Provision", link: "/food_provision" },
                { name: "Rescue Process", link: "/rescue_process" },
                { name: "Medical Treatment", link: "/medical-treatment" },
                { name: "Cow Genetics", link: "/cow-genetics" },
                { name: "Photo Gallery", link: "/photo-gallery" },
                { name: "Video Vault", link: "/video-vault" },
            ],
        },
        { name: "Photo Gallery ", link: "/photo-gallery" },
        { name: "Cattle Blog", link: "/cattle-blog" },
        { name: "Donation", link: "/donation" },
        { name: "Contact", link: "/contact" },
    ];

    const handleActive = (e) => {
        document.querySelectorAll(".main-menu ul li").forEach((el) => {
            el.classList.remove("active");
        });
        e.target.parentNode.classList += " active";
    };

    const subActive = (e) => {
        document.querySelectorAll(".main-menu ul li").forEach((el) => {
            el.classList.remove("active");
        });
        e.target.parentNode.parentNode.parentNode.classList += " active";
    };

    useEffect(() => {
        //SubMenu Dropdown Toggle
        if ($(".menu-area li.menu-item-has-children ul").length) {
            $(".menu-area .navigation li.menu-item-has-children").append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');
        }
        if ($(".mobile-menu").length) {
            var mobileMenuContent = $(".menu-area .main-menu").html();
            $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

            //Dropdown Button
            $(".mobile-menu li.menu-item-has-children .dropdown-btn").on("click", function () {
                $(this).toggleClass("open");
                $(this).prev("ul").slideToggle(500);
            });
            //Menu Toggle Btn
            $(".mobile-nav-toggler").on("click", function () {
                $("body").addClass("mobile-menu-visible");
            });

            //Menu Toggle Btn
            $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
                $("body").removeClass("mobile-menu-visible");
            });
        }

        $(".navbar-toggle").on("click", function () {
            $(".navbar-nav").addClass("mobile_menu");
        });
        $(".navbar-nav li a").on("click", function () {
            $(".navbar-collapse").removeClass("show");
        });

        $(".header-search > a").on("click", function () {
            $(".search-popup-wrap").slideToggle();
            return false;
        });

        $(".search-close").on("click", function () {
            $(".search-popup-wrap").slideUp(500);
        });
    }, []);

    return (
        <header ref={header}>
            <HeaderTopBar></HeaderTopBar>
            <div id="sticky-header" className="menu-area">
                <div className="container custom-container">
                    <div className="row">
                        <div className="col-12">
                            <div className="mobile-nav-toggler">
                                <i className="fas fa-bars" />
                            </div>
                            <div className="menu-wrap">
                                <nav className="menu-nav show">
                                    <Logo />
                                    <div className="navbar-wrap main-menu d-none d-xlg-flex">
                                        <ul className="navigation">
                                            {MENU_ITEMS.map((currentItem) => {
                                                return (
                                                    <li key={currentItem.name} className="">
                                                        {!currentItem.subMenu?.length ? (
                                                            <NavLink exact={true} className="navigation-links" activeClassName="active" to={currentItem.link}>
                                                                {currentItem.name}
                                                            </NavLink>
                                                        ) : (
                                                            <a className="navigation-links">{currentItem.name}</a>
                                                        )}
                                                        {currentItem.subMenu && (
                                                            <ul className="submenu">
                                                                {currentItem.subMenu.map(({ name, link }) => {
                                                                    return (
                                                                        <li key={name}>
                                                                            <NavLink to={link}>{name}</NavLink>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        )}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                    <div className="header-action d-none d-xlg-flex">
                                        <ul>
                                            <li className="header-search">
                                                <a href="/#">
                                                    <i className="flaticon-search" />
                                                </a>
                                            </li>

                                            <li className="header-btn">
                                                <NavLink to="/adoption" className="btn">
                                                    <span>Rescue </span> <img src="img/icon/milksplash.png" alt="" />
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>

                            <div className="mobile-menu">
                                <nav className="menu-box">
                                    <div className="close-btn">
                                        <i className="fas fa-times" />
                                    </div>
                                    <div className="p-4 logo-wrapper">
                                        <Logo />
                                    </div>
                                    <div className="menu-outer"></div>
                                    <div className="social-links">
                                        <ul className="clearfix">
                                            <li>
                                                <a href="/#">
                                                    <span className="fab fa-twitter" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                    <span className="fab fa-facebook-square" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                    <span className="fab fa-pinterest-p" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                    <span className="fab fa-instagram" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                    <span className="fab fa-youtube" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                            <div className="menu-backdrop" />
                        </div>
                    </div>
                </div>
                <div className="header-shape" style={{ backgroundImage: "url('img/bg/header_shape.png')" }} />
            </div>

            <div className="search-popup-wrap" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="search-close">
                    <span>
                        <i className="fas fa-times" />
                    </span>
                </div>
                <div className="search-wrap text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="title">... Search Here ...</h2>
                                <div className="search-form">
                                    <form>
                                        <input type="text" name="search" placeholder="Type keywords here" />
                                        <button className="search-btn">
                                            <i className="fas fa-search" />
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

const HeaderTopBar = () => {
    return (
        <div className="header-top-area">
            <div className="container custom-container">
                <div className="row align-items-center">
                    <div className="col-md-7">
                        <div className="header-top-left">
                            <ul>
                                <li>24/7 Cow Rescue Hotline : 9896784225 &ensp;| </li>
                                <li>
                                    <i className="far fa-clock" />
                                    <span> Gaushala Operating Hours : 8AM to 8PM everyday</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="header-top-right">
                            <ul className="header-top-social">
                                <li className="follow">Follow :</li>
                                <li>
                                    <a href="/#">
                                        <i className="fab fa-facebook-f" />
                                    </a>
                                </li>
                                <li>
                                    <a href="/#">
                                        <i className="fab fa-twitter" />
                                    </a>
                                </li>
                                <li>
                                    <a href="/#">
                                        <i className="fab fa-instagram" />
                                    </a>
                                </li>
                                <li>
                                    <a href="/#">
                                        <i className="fab fa-linkedin-in" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
