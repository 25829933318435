import { useTranslation } from "react-i18next";
import React from "react";

const useTranslationMod = (namespace, secondLanguage = "hi") => {
    const { t } = useTranslation(namespace);

    function tMod(name) {
        const englishText = t(name);
        const hindiText = t(name, { lng: secondLanguage });

        return (
            <>
                <span>{englishText}</span>
                <p className="second-language">{hindiText}</p>
            </>
        );
    }

    return { t: tMod };
};

export default useTranslationMod;
