import { useGSAP } from "@gsap/react";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import styled from "styled-components";
import ScrollTrigger from "gsap/ScrollTrigger";
import React from "react";


const VideoVault = () => {
    const containerRef = useRef();

    const ytNativeCmds = (elementRef, cmdName) => {
        console.log(elementRef, cmdName);
        elementRef.contentWindow.postMessage(
            JSON.stringify({
                event: "command",
                func: cmdName,
            }),
            "*"
        );
    };
    useGSAP(
        () => {
            const videoWrapper = gsap.utils.toArray(".video-container");
            [videoWrapper[0]].forEach((currentVideoElement) => {
                const iframe = currentVideoElement.querySelector("iframe");
                iframe.addEventListener("load", () => {
                    ScrollTrigger.create({
                        trigger: currentVideoElement,
                        start: "top 40%",
                        bottom: "bottom 40%",
                        markers: false,
                        onEnter: () => ytNativeCmds(iframe, "playVideo"), // Play video
                        onLeave: () => ytNativeCmds(iframe, "pauseVideo"), // Pause video when scrolled out
                        onEnterBack: () => ytNativeCmds(iframe, "playVideo"), // Resume when scrolling back up
                        onLeaveBack: () => ytNativeCmds(iframe, "pauseVideo"), // Pause when leaving from the top
                    });
                });
            });
        },
        { scope: containerRef }
    );

    return (
        <section ref={containerRef} className="py-5">
            <h1 className="text-center mb-5">Video Vault</h1>
            <VideoWrapper className="video-wrapper">
                {DATA.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <VideoContainer className="video-container">
                                <iframe width="560" height="315" src={item.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </VideoContainer>
                            <h3 className="mt-4">{item.title}</h3>
                            <hr className="bg-secondary w-100"></hr>
                        </React.Fragment>
                    );
                })}
            </VideoWrapper>
        </section>
    );
};

const VideoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
`;

const VideoContainer = styled.div`
    width: 70%;
    aspect-ratio: 16/9;
    overflow: hidden;
    border-radius: 15px;
    background: linear-gradient(90deg, #e0e0e0 25%, #f4f4f4 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;

    @media screen and (max-width: 700px) {
        width: 90%;
    }

    iframe {
        width: 100%;
        border-radius: 15px;
        height: 100%;
        object-fit: cover;
    }
`;

export default VideoVault;

const DATA = [
    {
        title: "Message from Our Chairman",
        video: "https://www.youtube.com/embed/FeMMsUeUVds?si=P_Vu2c4xy0R-mo5h?enablejsapi=1&autoplay=1",
    },
    // {
    //     title: "Message from Our President",
    //     video: "https://www.youtube.com/embed/tH2W4wKLqvo?si=W1BzmZLPPpegxMpZ?enablejsapi=1&autoplay=1",
    // },
    // {
    //     title: "Production @BPSG",
    //     video: "https://www.youtube.com/embed/tH2W4wKLqvo?si=W1BzmZLPPpegxMpZ?enablejsapi=1&autoplay=1",
    // },
    {
        title: "Message TO Our Public",
        video: "https://www.youtube.com/embed/B8yIX7x7lm0?si=0KqapV6ivV2nQ077?enablejsapi=1&autoplay=1",
    },
    // {
    //     title: "Message from Geonix",
    //     video: "https://www.youtube.com/embed/tH2W4wKLqvo?si=W1BzmZLPPpegxMpZ?enablejsapi=1&autoplay=1",
    // },
];
