import React from 'react';

const AboutPage = () => {
    return (
        <div className="breeder-details-page mt-20"  >
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className=" text-center page-title">About </h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutPage;
