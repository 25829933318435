import React, { useEffect } from "react";
import "magnific-popup";
import $ from "jquery";
import  useTranslationMod  from "../hooks/useTranslationMod";
import { Link } from "react-router-dom";

function Faq({ afterElment }) {
    const {t} =  useTranslationMod();

    useEffect(() => {
        /* magnificPopup video view */
        $(".popup-video").magnificPopup({
            type: "iframe",
        });
    }, []);

    return (
        <section className={afterElment + " faq-bg"}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="faq-img-wrap">
                            <img src="img/images/faq_tv.png" className="img-frame" alt="" />
                            <img src="img/other/tv-inside.jpeg" className="main-img" alt="" />
                            <Link to="/https://youtu.be/FeMMsUeUVds?si=KoL0FEKFqLBeuZKO" className="popup-video">  </Link>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="faq-wrapper">
                            <div className="section-title mb-35">
                                <h5 className="sub-title">Common Questions (FAQs)</h5>
                                <h2 className="title">{t('peoples_gaushala')}</h2>
                            </div>
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                What is BPS Gaushala?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div className="card-body">A protective Sanctuary (shelter) for stray andailing cows in Kapro Village, Hisar District, Haryana. Under the chairmanship of eminent philanthropist, Shri Girish Kumar Jain, BPSG shelters over 1000 cattle of various breeds. BPSG offers some of the best facilities in the country for cattle rescue,recuperation, nourishment, and long-term sustenance. </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Importance of Cow in Indian Culture
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                        <div className="card-body">In Hinduism, the cow is considered a sacred animal and symbolizes wealth, strength, and motherly love. It is believed to be the earthly representative of the divine and nourishing Mother Goddess, who represents fertility and bountifulness. Their milk is believed to have a purifying effect on human bodies.</div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                What is the Cow Protection Movement?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div className="card-body">A popular movement in India (Bharat) aiming to protect cows from unlawful slaughter. Cow slaughter is shunned for two reasons: the cow's association with Lord Krishna (a cowherder), and because of cattle's centrality to rural livelihood. Legislation against cattle slaughter is in place throughout most states and union territories of India.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="faq-shape">
                <img src="img/other/cows-icons/cow-head.png" alt="" />
            </div>
            <div className="faq-shape" style={{ left: "2%", right: "unset", bottom: "-20px" }}>
                <img src="img/other/cows-icons/cow6.png" alt="" />
            </div>
        </section>
    );
}

export default Faq;
