import React, { useRef } from "react";
import styled from "styled-components";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Donation = () => {
    const Doconatiner = useRef();

    useGSAP(
        () => {
            const timeline = gsap.timeline({
                defaults: { duration: 1, ease: "power2.out" }, // Optional: Set global defaults
            });
            timeline.from(".cardi", {
                opacity: 0,
                x: 100,
                stagger: 0.3,
                duration: 0.5,
            });
        },
        { scope: Doconatiner }
    );

    return (
        <DonationContainer ref={Doconatiner} className="container">
            <CardWrapper>
                {cardsData.map((_, index) => (
                    <div key={index} className="cardi">
                        <img src={_.image} className="card-img-top" alt="Card" />
                        <div className="card-body">
                            <h3 className="card-title">{_.title}</h3>
                            <div className="card-text">
                                {_.description.map((innerText) => (
                                    <h6 className="text-white mb-0"> {innerText} </h6>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </CardWrapper>
        </DonationContainer>
    );
};

const DonationContainer = styled.div`
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    text-align: center;
`;

const CardWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;

    .cardi {
        width: 20rem;
        margin: 1rem;
        border: none;
        border-radius: 12px;
        overflow: hidden;
        cursor: pointer;
    }

    .card-img-top {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
    }

    .card-body {
        background: var(--main-color);
        padding: 1.25rem;
        text-align: center;
        height: 100%;
    }

    .card-title {
        color: white;
    }

    .card-text {
        font-size: 0.9rem;
        margin-bottom: 1rem;
        color: white;
    }

    .btn {
        background-color: var(--main-color);
        border: none;
        color: #fff;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        transition: background-color 0.3s ease;
    }

    @media (max-width: 768px) {
        .card {
            width: 100%;
        }
    }
`;

export default Donation;

const cardsData = [
    {
        id: 1,
        title: "Sh. Satish kumar Jain",
        description: [" Rt. EX .EN "],
        image: "/img/donation/P-01.jpg",
        link: "#",
    },

    {
        id: 3,
        title: "Sh. Naresh kumar",
        description: ["Toronto Canada"],
        image: "/img/donation/P-03.jpg",
        link: "#",
    },
    {
        id: 4,
        title: "Sh Gautam Jain",
        description: ["Vapi Gujrat"],
        image: "/img/donation/P-04.jpg",
        link: "#",
    },
    {
        id: 5,
        title: "Sh Sanjay Jain",
        description: ["Industrialist & Philanthropist"],
        image: "/img/donation/P-05.jpg",
        link: "#",
    },
    {
        id: 6,
        title: "Sh. Suresh Singhal",
        description: ["Businessman "],
        image: "/img/donation/P-06.jpg",
        link: "#",
    },
    {
        id: 7,
        title: "Dr. T R Mittal ",
        description: ["Businessman & Philanthropist"],
        image: "/img/donation/P-07.jpg",
        link: "#",
    },
    {
        id: 2,
        title: "Mr. Gaurav Jain",
        description: ["Founder & Director", "Geonix International Pvt Ltd ", "Entrepreneur & Businessman", "New Delhi, India"],
        image: "/img/donation/P-02.jpg",
        link: "#",
    },
    {
        id: 8,
        title: "Ch. Jaibir Singh ",
        description: ["Late Ganga Bisan Kaliraman Transporter"],
        image: "/img/donation/P-08.jpg",
        link: "#",
    },
];
