import React from "react";
import styled from "styled-components";

const FoodProvision = () => {
    const foodProvisionContent = [
        {
            image: "img/other/food page/dry_Food.jpg",
            title: "Dry Food",
            description: "",
        },
        {
            image: "img/other/food page/grass_food.jpg",
            title: "Green Food",
            description: "",
        },
        {
            image: "img/other/food page/Water1.jpg",
            title: "Water",
            description: "",
        },
    ];

    return (
        <div className="breeder-details-page mt-20">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="page-title text-center">Food Provision</h2>
                    </div>
                </div>
                {foodProvisionContent.map(({ image, description, title }) => {
                    return (
                        <RowContainer className="row py-5">
                            <div className="image-box col-md-5">
                                <Image src={image} alt="Food Provision" />
                            </div>
                            <div className="content-box col-md-7">
                                <div>
                                    <h3> {title} </h3>
                                    <p> {description} </p>
                                </div>
                            </div>
                        </RowContainer>
                    );
                })}
            </div>
        </div>
    );
};

const RowContainer = styled.div`
    margin: 20px 0;

    @media screen and (min-width: 768px) {
        &:nth-child(even) .content-box {
            order: -1;
        }
    }

    .content-box {
        margin-top: 10px;
    }
`;

const Image = styled.img`
    max-width: 100%;

    height: auto;
    border-radius: 10px;
`;
export default FoodProvision;
