import React from "react";
import styled from "styled-components";

// Styled component for error message container
const ErrorContainer = styled.div`
    width: 100%;
  
    padding: 20px;
    background-color: #f8d7da;
    color: #721c24;
    border-radius: 5px;
    border: 1px solid #f5c6cb;
    margin: 20px auto;
    display: flex;
    align-items: center;
`;

const ErrorIcon = styled.span`
    margin-right: 10px;
    font-size: 20px;
    color: #721c24;
`;

const ErrorMessage = ({ message, onRetry }) => {
    return (
        <ErrorContainer>
            <ErrorIcon>&#9888;</ErrorIcon> {/* Warning icon */}
            <div>
                <p>{message}</p>
                {onRetry && (
                    <button onClick={onRetry} style={{ color: "#721c24", border: "none", background: "none", cursor: "pointer" }}>
                        Retry
                    </button>
                )}
            </div>
        </ErrorContainer>
    );
};

export default ErrorMessage;
