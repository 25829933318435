import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import App from "./App";
import i18nConfig from "./configs/i18n.config";
import { axiosSetup } from "./configs/axios.config";
import emailjsConfig from "./configs/emailjs.config";

i18nConfig();
axiosSetup();
emailjsConfig();
ReactDOM.render(<App />, document.getElementById("root"), function () {
    $("#preloader").delay(0).fadeOut();
});
