import { useEffect, useState } from "react";
import axios from "axios";

const useSingleBlog = (slug) => {
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!slug) return;

        setLoading(true);
        setError(null);

        axios
            .get(`/blog/`, { params: { slug } })
            .then((response) => setBlog(response.data.data))
            .catch((err) => setError(err))
            .finally(() => setLoading(false));

    }, [slug]);

    return { blog, loading, error };
};

export default useSingleBlog;
