import React from "react";
import styled, { keyframes } from "styled-components";

const Loader = ({ size, color }) => {
    return (
        <Wrapper>
            <StyledLoader size={size} color={color} />
            
        </Wrapper>
    );
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  
`;

const StyledLoader = styled.div`
    width: ${({ size }) => size + "px" || "50px"};
    height: ${({ size }) => size +"px" || "50px"};
    border: 4px solid ${({ color }) => color || "var( --main-color)"};
    border-top: 4px solid transparent;
    border-radius: 50%;
    animation: ${spin} 1s linear infinite;
    margin: auto;
`;

export default Loader;
