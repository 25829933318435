import axios from "axios";
import { useEffect, useState } from "react";

const useBlog = () => {
    const [allblogs, setAllBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        setError(null);

        axios
            .get(`/blog/all`)
            .then((response) => {
                setAllBlogs(response.data);
            })
            .catch((err) => setError(err))
            .finally(() => setLoading(false));
    }, []);

    return { loading, error, allblogs };
};

export default useBlog;
